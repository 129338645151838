@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "OpenSans";
  src: url("OpenSans-Regular.woff") format("woff");
}

@layer base {
  html {
    font-family: "OpenSans", sans-serif;
    @apply 
      bg-white
      dark:bg-[#1f232b]
      text-gray-700
      dark:text-neutral-100
      tracking-wide
      leading-8
      font-light
      min-h-screen
  }
}

.title {
  @apply 
    text-3xl
    md:text-5xl
    lg:text-6xl
    tracking-tight
    my-4
    text-gray-900 
    dark:text-gray-100
}

.subtitle {
  @apply 
    text-2xl
    lg:text-4xl
    font-bold 
    tracking-tight
    my-4
    text-gray-900 
    dark:text-gray-100
}

.title, .subtitle {
  @apply 
    lg:leading-[4rem];
}

.button {
  @apply 
    font-bold 
    px-3.5
    py-2
    rounded-md 
    text-lg
    sm:text-2xl
    shadow-sm
    items-center
}

.button-small {
  @apply 
    font-bold 
    px-3.5
    py-2
    rounded-md 
    text-lg 
    shadow-sm
    items-center
}